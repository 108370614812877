import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`A sidebar is a component which orients a panel relative to content and displays additional information or navigation options alongside the main content of a page. It was originally built to house jump links so that page content could be oriented relative to the jump links and a commonly used design pattern in web and mobile applications.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/sidebar/sidebar-anatomy.svg",
      "alt": "anatomy guideline side bar component"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Sidebar container:`}</strong>{` This is the outermost container for the sidebar, which holds all the other elements of the sidebar.`}</li>
      <li parentName="ol"><strong parentName="li">{`Sidebar header:`}</strong>{` This element usually contains a title or a logo that identifies the sidebar.`}</li>
      <li parentName="ol"><strong parentName="li">{`Sidebar menu:`}</strong>{` This is the main navigation menu of the sidebar that contains links to various sections of the application or website.`}</li>
      <li parentName="ol"><strong parentName="li">{`Sidebar profile:`}</strong>{` This element displays information about the user, such as their profile picture and name.`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Sidebar is a navigation menu to provide access to destinations and app functionality, such as switching accounts. They can either be permanently on-screen or controlled by a navigation menu icon . The Sidebar component is commonly used in web and mobile applications to provide additional navigation options or to display contextual information related to the main content of a page. It can be used as a standalone element or in combination with other components such as a top navigation bar or a footer.`}</p>
    <p><strong parentName="p">{`Common places buttons appear :`}</strong>{` Mobile app homepage , website homepage and other pages when needed etc.`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li>You have long content that you want to keep out of sight at first.</li>
      <li>To keep content like structured navigation or screen-specific help accessible.</li>
      <li>To provide users with easy access to account information or settings.</li>
      <li>To help users navigate through a large amount of content, such as in an E-commerce or news website.</li>
      <li>To provide users with quick access to support resources or frequently asked questions.</li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li>To force users to complete an action before continuing.</li>
      <li>For small content or few options closer to the original context.</li>
      <li>For small bits of information focused on a single action.</li>
      <li>When the additional information or navigation options are not relevant or necessary for the user.</li>
      <li>When the Sidebar component would cause visual clutter or distract from the main content of a page.</li>
      <li>When the Sidebar component would negatively impact the user experience on smaller screens or devices.</li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`A sidebar is a component which orients a panel relative to content. It was originally built to house jump links so that page content could be oriented relative to the jump links. Screen reader users should be able to navigate to the sidebar and all the contents of the sidebar should be read by the screen reader. Here are some considerations for ensuring accessibility in the Sidebar component:`}</p>
    <ul>
      <li parentName="ul">{`Keyboard Navigation: Ensure that users can navigate the Sidebar using only a keyboard. All interactive elements, including buttons and links, should be accessible through keyboard shortcuts.`}</li>
      <li parentName="ul">{`Screen Readers: The Sidebar should be screen reader compatible, with all elements labeled correctly and in a logical order. ARIA attributes should be used to provide additional information to screen reader users.`}</li>
      <li parentName="ul">{`Color Contrast: Ensure that the Sidebar has sufficient color contrast between text and background. This will make it easier for users with visual impairments to read the content.`}</li>
      <li parentName="ul">{`Resize and Zoom: The Sidebar should be resizable and zoomable to accommodate users who need to increase the size of the content.`}</li>
      <li parentName="ul">{`Focus Indicators: Ensure that all interactive elements in the Sidebar have clear focus indicators. This will help users with visual impairments to navigate the Sidebar.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle='Do' caption="Use a consistent design and layout for the Sidebar component throughout the application." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/sidebar/side-bar-a11y-do.png",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Use an inconsistent design and layout for the Sidebar component throughout the application." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/sidebar/side-bar-a11y-dont.png",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When creating Sidebar content of the Sidebar component will vary depending on the specific use case, but it typically includes:`}</p>
    <ul>
      <li parentName="ul">{`Navigation links to other sections of the application or related content.`}</li>
      <li parentName="ul">{`Contextual information related to the main content of a page, such as related articles or products.`}</li>
      <li parentName="ul">{`Account information or settings.`}</li>
      <li parentName="ul">{`Support resources or frequently asked questions. :`}</li>
      <li parentName="ul">{`Keep all labels similar in length, context, and type. If using nouns to title pages and links, use nouns in the navigation.`}</li>
      <li parentName="ul">{`Be specific, tell users exactly where they are going and what they can expect.`}</li>
      <li parentName="ul">{`Use sentence case in navigation links.`}</li>
      <li parentName="ul">{`Try to keep navigation link labels under 20 characters to avoid text wrapping menu items must be short enough to scan, but long enough to be clear.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle='Do' caption="Use clear and concise labels for navigation links or other content in the Sidebar." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/sidebar/side-bar-content-do.png",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Use an unclear and verbose lables for navigation links or other content in the Sidebar." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/sidebar/side-bar-content-dont.png",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      